<template>
  <FocusTrap>
    <div id="partybalance_card">
      <h3>Party Balance</h3>

    </div>

  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'PartyBalance',
  store,
  data () {
    return {
      readonly: false,
      ledgers: [],
      detail_from : JSON.parse('{"idx":0,"adjust_type":0, "ledger_id":0,"ledger":{"id":0,"status":"Active","name":"","alias":"","group":{"id":0,"name":"","alias":"","parent":0,"nature":0},"open_bal":0,"gstin":""},"remarks":"","debit":0,"credit":0}'),
      detail_to : JSON.parse('{"idx":0,"adjust_type":0, "ledger_id":0,"ledger":{"id":0,"status":"Active","name":"","alias":"","group":{"id":0,"name":"","alias":"","parent":0,"nature":0},"open_bal":0,"gstin":""},"remarks":"","debit":0,"credit":0}'),
    }
  },
  created () {
    const self = this;
  },
  mounted () {
    const self = this;
  },
  methods:{
    loadParties(){
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#partybalance_card').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/party_ledgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            resp.data.forEach(function (ldgr) {
              self.$data.ledgers.push(ldgr);
            });
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes', text: err.toString(), type: 'error' });
      }).finally(function () {
        $('#partybalance_card').unblock();
      });

    },
  }
}
</script>

<style scoped>

</style>
